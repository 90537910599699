/* You can add global styles to this file, and also import other style files */
$icon-font-path: "../../fonts/bootstrap/";

@import 'variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap';
@import 'font-awesome/css/font-awesome.css';
@import 'bootstrap';

html,
body {
  height: 100%;
}

html {
  font-size: 14px;
}

.right-align {
  text-align: right;
}

.wrapper {
  display: flex;
  min-height: 100%;
}

.page-content-wrapper {
  overflow: hidden;
  flex: 1;
}

//==============
// Tables
//==============

.table th a,
.table thead th a {
  cursor: pointer;
}

@mixin sort() {
  margin-left: 5px;
  font: normal normal normal 14px/1 FontAwesome, sans-serif;
}

.sort-asc::after {
  content: "\f0de";

  @include sort();
}

.sort-desc::after {
  content: "\f0dd";

  @include sort();
}

.sort-none::after {
  content: "\f0dc";

  @include sort();
}

//==============
// Forms
//==============

.pull-right {
  .form-control {
    display: inline-block;
  }
}

.form-label {
  word-wrap: break-word;
}

//==============
// Panels
//==============

.panel-heading {
  .heading-search {
    display: inline-block;
  }

  .panel-title {
    line-height: 35px;
  }

  .pull-right {
    margin-top: -6px;
  }
}

//==============
// Centered Columns
//==============

/* centered columns styles */
.row-centered {
  text-align: center;
}

.col-centered {
  display: inline-block;
  float: none;

  /* reset the text-align */
  text-align: left;

  /* inline-block space fix */
  margin-right: -4px;
}

//==============
// File Input
//==============
.input-wrapper {
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  background-color: #e6e6e6;
  border: 1px solid;
  display: inline-block;
  padding: 6px;
  vertical-align: middle;
}

.file-input {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  /* This makes the button huge so that it can be clicked on */
  font-size: 50px;
}

.file-input-hidden {
  /* Opacity settings for all browsers */
  opacity: 0;
  -moz-opacity: 0;
}

.file-input-selection {
  margin-top: 5px;
}

/* Dynamic styles */
.input-wrapper:hover {
  background-color: #a6a6a6;
}

.btn-centered {
  margin-bottom: 20px;
  text-align: center;
}

//=======================
// Tables with filter-row
//=======================

.filter-buttons {
  width: 10%;
}

.search-column {
  border-top: 0 !important;
}

.table-striped.search-table {
  & > thead > tr > th {
    background-color: #f5f5f5;
  }

  & > tbody > tr {
    &:nth-child(even) > td {
      background-color: #f5f5f5;
    }

    &:nth-child(odd) > td {
      background-color: #fff;
    }
  }
}

// Workaround for https://github.com/primefaces/primeng/issues/9741
.p-inputgroup {
  p-dropdown:not(.p-inputgroup-addon) .p-dropdown {
    width: 100%;
  }

  .p-float-label {
    &:not(:last-child) {
      .p-dropdown {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child) {
      .p-dropdown {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  p-dropdown:not(.p-inputgroup-addon) {
    &:not(:last-child) {
      .p-dropdown {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child) {
      .p-dropdown {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.raise {
  position: relative;
  z-index: 1;
}
